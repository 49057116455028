import React, { useState } from 'react'
import './Accessibility.css'
import '../components/pages/Home.css'
import 'bootstrap-icons/font/bootstrap-icons.css'

function Accessibility(){
    const [clickBig, setClickBig] = useState(false)
    const [little, setLittle] = useState(false)
    const [gray,setGray] = useState(false)
    const [high_contrast, setHighContrast] = useState(false)
    const [highlight , setHighlightLinks ] = useState(false)
    const [white, setWhite]= useState(false)
    const [readable, setReadable] = useState(false)
    const [reverse_contrast, setReverseContrast] = useState(false)
    const [click, setClick] = useState(false)
    const handleClick = () => setClick(!click)

    const [cssBig, setCssBig]= useState([])
    const [cssSmall, setCssSmall]= useState([])
    const [cssGray, setCssGray]= useState([])
    const [cssHigh, setCssHigh]= useState([])
    const [cssLinks, setCssLinks]= useState([])
    const [cssFont, setCssFont]= useState([])
    const [cssReverse, setCssReverse] = useState([])
    const [cssWhite, setCssWhite] = useState([])
    
    var sheet = document.createElement('style')
    
    const increase =()=>{
        setClickBig(!clickBig)
        if (!clickBig){
            if(little){
                document.body.removeChild(cssSmall)
                setLittle(false)
            }
            sheet.innerHTML = " h1 { font-size: calc(1.375rem + 2.5vw) !important;} h2 {font-size: calc(1.325rem + 1.9vw) !important} h3 {font-size: calc(1.6rem + 0.6vw) !important;} h4 {font-size: calc(1.275rem + 1.3vw) !important ;} div, p{font-size: 18px !important;} ..nav-menu.active{font-size: 17px;}";
            setCssBig(sheet)
            document.body.appendChild(sheet);
        }
        else{
            /*sheet.innerHTML = " h1 { font-size: calc(1.375rem + 1.5vw) !important;} h2 {font-size: calc(1.325rem + 0.9vw) !important} h3 {font-size: calc(1.3rem + 0.6vw) !important;} div, p{font-size: 16px !important;}";
            document.body.appendChild(sheet);*/
            document.body.removeChild(cssBig)
        }
        
    }
    const decrease =()=>{
        setLittle(!little)
        if (!little){
            if(clickBig){
                document.body.removeChild(cssBig)
                setClickBig(false)
            }
            sheet.innerHTML =
              " h1 { font-size: calc(1.075rem + 1.5vw) !important;} h2 {font-size: calc(1.025rem + 0.9vw) !important} h3 {font-size: calc(1.0rem + 0.6vw) !important;} h4 {front-size: calc(1.075rem + 0.3vw) !important;} div, p{font-size: 14px !important;}";
            setCssSmall(sheet)
            document.body.appendChild(sheet);
        }
        else{
            //sheet.innerHTML = " h1 { font-size: calc(1.375rem + 1.5vw) !important;} h2 {font-size: calc(1.325rem + 0.9vw) !important} h3 {font-size: calc(1.3rem + 0.6vw) !important;} div, p{font-size: 16px !important;}";
            //document.body.appendChild(sheet);
            document.body.removeChild(cssSmall)
        }

    }
    const grayBackground =()=>{
        
        setGray(!gray)
        if(!gray){
            sheet.innerHTML =  "div {filter: grayscale(1);} .Filling-in-details, .part2{z-index: 1;}";
            setCssGray(sheet)
            document.body.appendChild(sheet);
        }
        else{
            //sheet.innerHTML =  "div { filter: grayscale(0);}";
            //document.body.appendChild(sheet);
            document.body.removeChild(cssGray)
          
        }
    }
    const highContrast =()=>{
        setHighContrast(!high_contrast)
        if(!high_contrast){
            if(reverse_contrast){
                document.body.removeChild(cssReverse)
                setReverseContrast(false)
            }
            sheet.innerHTML =
              ".home, .projects, .services, .user, .container-us, .aboutUs, .login, .process, .nav-menu.active, .title-service, .icon-about {background-color: black !important;} .part2, .column, .column2, .card, .a-level {background-color: black} .advantage, .serv, .Filling-in-details, .input-contact, .inputs, .btn-login, .input-login, .not-done, .Navbar, .display, .Paragraph {background-color: black !important; border: 1px solid white} h1, small, .label-contact, .btn-login {color: #a2ded2 !important;} h3, p, .input-login {color: #dce9e7 !important;} h2, .send-btn {color: lightsteelblue !important;} .right-arrow, .left-arrow, .fa-user, .fa-lock, a,.fas, .far , .acc-btn{color: white !important;}";
            setCssHigh(sheet)
            document.body.appendChild(sheet)
        }else{
        
            document.body.removeChild(cssHigh)
        }

    }
    const highlightLinks=()=>{
        setHighlightLinks(!highlight)
        
        if(!highlight){
            sheet.innerHTML=" a {text-decoration: revert !important;}"
            setCssLinks(sheet)
            document.body.appendChild(sheet)

        }
        else{
            document.body.removeChild(cssLinks)
  
        }
    }
    const readableFont =()=>{
        setReadable(!readable)
        if(!readable){
            sheet.innerHTML = "h1, h2, h3, h4, p, a, button, .text-home, .title-home, .nav-links, .nav-links-1, .title-projects, input, label{font-family: sans-serif !important;}"
            setCssFont(sheet)
            document.body.appendChild(sheet)
        }else{
            /*
            sheet.innerHTML = "h1, h2, h3, p, a, input, label {font-family:'Fredoka', sans-serif !important;} .facebook, button {font-family: var(--bs-body-font-family) !important;} .text-home, .title-home, .nav-links, .nav-links-1 {font-family: 'Suez One', serif !important;} .title-projects {font-family: 'Rubik Dirt', cursive !important;}"
            document.body.appendChild(sheet)*/
            document.body.removeChild(cssFont)

        }
    }
    const whiteBackground =()=>{
        setWhite(!white)
        if(!white){
            if(reverse_contrast){
                document.body.removeChild(cssReverse)
                setReverseContrast(false)
            }
            if(high_contrast){
                document.body.removeChild(cssHigh)
                setHighContrast(false)
            }
            sheet.innerHTML =
              ".footer-container,.nav-menu.active, .part2, .Navbar, .serv,.service_title, .inputs, .btn-login, .send-btn, .title-service {background-color: white !important;} .advantage, .Filling-in-details, .display, .icon-about ,.Paragraph {background-color: white !important; border: 1px solid black} .icon-about ,.Paragraph{ color: black !important;}";
            setCssWhite(sheet)
            document.body.appendChild(sheet)
        }
        else{
            document.body.removeChild(cssWhite)
        }
    }
    const reverseContrast =()=>{
         setReverseContrast(!reverse_contrast)
         if(!reverse_contrast){
            if(high_contrast){
                document.body.removeChild(cssHigh)
                setHighContrast(false)
            }
            sheet.innerHTML =
              ".home, .projects, .services, .user, .container-us, .aboutUs, .login, .process, .nav-menu.active, .title-service {background-color: black !important;} .part2, .column, .column2, .card, .a-level {background-color: black} .advantage, .serv, .Filling-in-details, .input-contact, .inputs, .btn-login, .input-login, .not-done, .Navbar, .display, .Paragraph, .icon-about {background-color: black !important; border: 1px solid white} h1, small, .label-contact, .acc-btn, .btn-login, h3, p, .input-login, h2, h4, .send-btn, .right-arrow, .left-arrow, .fa-user, .fa-lock, a ,.fa-bar, .fas, .far{color: yellow !important;}";
            
            setCssReverse(sheet)
            document.body.appendChild(sheet)
         }else{
            document.body.removeChild(cssReverse);
         }
    }
    const reset =()=>{
        if(gray){
            
            document.body.removeChild(cssGray)
            setGray(false)
        }
        if(clickBig){
            document.body.removeChild(cssBig)
            setClickBig(false)
        }
        if(little){
            document.body.removeChild(cssSmall)
            setLittle(false)
        }
        if(high_contrast){
            document.body.removeChild(cssHigh)
            setHighContrast(false)
        }
        if(white){
            document.body.removeChild(cssWhite)
            setWhite(false)
        }
        if(highlight){
            document.body.removeChild(cssLinks)
            setHighlightLinks(false)
        }
        if(readable){
            document.body.removeChild(cssFont)
            setReadable(false)
        }
        if(reverse_contrast){
            document.body.removeChild(cssReverse)
            setReverseContrast(false)
        }else{
            return
        }
        
    }
    
    return (
      <div className="accessibility" tabIndex="0">
        {click ? (<>
          <i className="bi bi-x close-acc" onClick={handleClick}></i>
          <ul className="display">
            

            <h3 className="acc-title">כלי נגישות</h3>
            <li>
              {clickBig ? (
                <i className="bi bi-check-lg"></i>
              ) : (
                <i className="bi bi-zoom-in"></i>
              )}
              <button className="acc-btn" onClick={increase}>
                הגדלת טקסט
              </button>
            </li>
            <li>
              {little ? (
                <i className="bi bi-check-lg"></i>
              ) : (
                <i className="bi bi-zoom-out"></i>
              )}
              <button className="acc-btn" onClick={decrease}>
                הקטנת טקסט
              </button>
            </li>
            <li>
              {gray ? (
                <i className="bi bi-check-lg"></i>
              ) : (
                <i className="bi bi-upc"></i>
              )}
              <button className="acc-btn" onClick={grayBackground}>
                גווני אפור
              </button>
            </li>
            <li>
              {high_contrast ? (
                <i className="bi bi-check-lg"></i>
              ) : (
                <i className="bi bi-circle-half"></i>
              )}
              <button className="acc-btn" onClick={highContrast}>
                ניגודיות גבוהה
              </button>
            </li>
            <li>
              {reverse_contrast ? (
                <i className="bi bi-check-lg"></i>
              ) : (
                <i className="bi bi-eye"></i>
              )}
              <button className="acc-btn" onClick={reverseContrast}>
                ניגודיות הפוכה
              </button>
            </li>
            <li>
              {white ? (
                <i className="bi bi-check-lg"></i>
              ) : (
                <i className="bi bi-lightbulb"></i>
              )}
              <button className="acc-btn" onClick={whiteBackground}>
                רקע בהיר
              </button>
            </li>
            <li>
              {highlight ? (
                <i className="bi bi-check-lg"></i>
              ) : (
                <i className="bi bi-link-45deg"></i>
              )}
              <button className="acc-btn" onClick={highlightLinks}>
                הדגשת קישורים
              </button>
            </li>
            <li>
              {readable ? (
                <i className="bi bi-check-lg"></i>
              ) : (
                <i className="bi bi-fonts"></i>
              )}
              <button className="acc-btn" onClick={readableFont}>
                פונט קריא
              </button>
            </li>
            <li>
              <i className="bi bi-arrow-counterclockwise"></i>
              <button className="acc-btn" onClick={reset}>
                איפוס
              </button>
            </li>
          </ul>
        </>) : (
          <button className="btn-img" onClick={handleClick}>
            <img className="img-accessible" src="/acc1.png" alt="Accessible"/>
          </button>
        )}
      </div>
    );
}
export default Accessibility