import React , { useState } from 'react'
import './ContactUs.css';
import emailjs from 'emailjs-com';
import 'bootstrap-icons/font/bootstrap-icons.css'


const ContactUs = () =>{
    // Set a same-site cookie for first-party contexts
document.cookie = 'cookie1=value1; SameSite=Lax';
// Set a cross-site cookie for third-party contexts
document.cookie = 'cookie2=value2; SameSite=None; Secure';
   const [values, setValues] = useState({
      name: '',
      email: '',
      phone: '',
      city: '',
      street: '',
      number: ''
   })
   
   const [emailSent, setEmailSent] = useState(false);


   const submit = async(e) => {
      e.preventDefault()
      var x = document.getElementById("snackbar");
      x.className="show";
      // console.log("kjnkj;n")
      if (values.name && values.email && values.phone && values.city) {
         const {name, email, phone, city, street, number} = values
         console.log("f",email)
         const serviceId = 'service_8jt4lh3';
         const templateId = 'template_cmhpnlo';
         const userId = 'user_nr1HZgyJuKrWAYlt2uhGy';
         const templateParams = {
             name,
             email,
             phone,
             city,
             street,
             number
         };

          await emailjs.send(serviceId, templateId, templateParams, userId)
             .then(response => console.log(response))
             .then(error => console.log(error));
  
         //  setValues({})
          setEmailSent(!emailSent);
          await setTimeout(function(){x.className = x.className.replace("show", ""); }, 3000);
           
          
      } else {
          alert('Please fill in all fields.');
      }
      window.location.reload();
  }
 

   return(
     <div className="container-us" >
      <h1 className='contact-title'>תמיד כאן לשירותכם</h1>
      <h3 className='contact-title2'>מוזמנים להתקשר, לשלוח אימייל או להשאיר פרטים ואנחנו נחזור אליכם. </h3>
      <div className="Filling-in-details"> 
            <h2 className="txt2"> צרו קשר</h2>
       
            <form className="form-contact" onSubmit={submit}>
               <div className="inputs-contactus">
                  <input className='input-contact' name="name" placeholder="הזן שם מלא" type="text" value={values.name} onChange={e => setValues({...values, name: e.target.value})}/>
               
                  <input className='input-contact' name="email" placeholder="example@gmail.com" type="email" value={values.email} onChange={e => setValues({...values, email: e.target.value})} />
            
                  <input className='input-contact' name="phone" placeholder="הזן מספר הטלפון" type="tel" value={values.phone} onChange={e => setValues({...values, phone:e.target.value})}/>
               </div>
               <div className="input">
                  
                  <input className='input-contact' name="city" placeholder="עיר" type="text" value={values.city} onChange={e => setValues({...values, city: e.target.value})}/>
                  <input className='input-contact' name="street" placeholder="רחוב" type="text" value={values.street} onChange={e => setValues({...values, street:e.target.value})}/>
                  <input className='input-contact-number' name="number" placeholder="מס' בית" type="number" value={values.number} onChange={e => setValues({...values, number:e.target.value})}/><br/>
               </div>
               <button type='submit' className="btn btn-danger"> שלח </button>
               <div id="snackbar">  הפרטים נשלחו בהצלחה, אנחנו נחזור אלייך</div>
            </form>
         </div>
      
      <div className="row-contact">
         <div className='map-section'>
         <iframe className='map-iframe' id='gmap_canvas' width="100%" height="420" title='map-location' scrolling="no" src="https://maps.google.com/maps?width=100%25&amp;height=520&amp;hl=en&amp;q=%D7%99%D7%A9%D7%A8%D7%90%D7%9C%20%D7%91%22%D7%A7%2027%20%D7%AA%D7%9C%20%D7%90%D7%91%D7%99%D7%91,%20%D7%99%D7%A9%D7%A8%D7%90%D7%9C+(%D7%9E%D7%A2%D7%95%D7%A3%20%D7%9E%D7%A2%D7%9C%D7%99%D7%95%D7%AA)&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.gps.ie/">gps devices</a></iframe>
         
         </div>
         <div className='contactInfo'>
            <div className='info'>
               <h3 className='title-contact'>כתובת <i className="bi bi-geo-alt" style={{color:'rgb(239, 72, 72)'}}></i></h3>
               <h5 className='p-contact'>ישראל ב"ק 27, תל אביב</h5>
               
            </div>
            <div className='info'>
               <h3 className='title-contact'>טלפון <i style={{color:'rgb(239, 72, 72)'}} className="bi bi-telephone"></i></h3>
               <a download className='phone' href='tel:+97235618432'>03-5618432</a>
                  
               
            </div>
            <div className='info'>
               <h3 className='title-contact'>אימייל <i style={{color:'rgb(239, 72, 72)'}} className="bi bi-envelope-at"></i></h3>
               <a download className='email' href='mailto:maof.elevators1@gmail.com'>maof.elevators1@gmail.com</a>
               
            </div>
         </div>
         
      </div>
      
{/*      
      <div className='contact-image'>
         <img className = 'impcontact' src='/contact1.jpg' alt='תמונה לעיצוב דף צור קשר'/>
      </div> */}
         
         
   </div>
   );
};

export default ContactUs;