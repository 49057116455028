import React ,{useState}from "react";
import {doc, setDoc, collection} from 'firebase/firestore'
import {db} from '../firebase-config'

function FaultCall (props){
    const [values,setValues] = useState({
        date:'',
        fault:'',
        faultType:''
        
    })
    const saveFault=async(e)=>{
         e.preventDefault()
         const uuid = doc(collection(db, "callsAndFaults")).id
         const callCollection = doc(db, "callsAndFaults", uuid )
         setDoc(callCollection,{"call": values.fault, "fault":values.faultType, "name": props.id, "date": values.date},{merge: true}).then(()=>{
             console.log("Successful")
             props.close()
         }).catch((err)=>{
             console.log(err)
         })
       
    }
    return(
        <div>
            <form onSubmit={saveFault}>
            <div >
                <h3 className =''>כתוב את התקלה של הלקוח ותאריך השיחה</h3>
                <label>תאריך</label>
                <input value={values.date} name="date" placeholder='callDate' type='date' onChange={(e)=>{setValues({...values, date: e.target.value})}}/>
            </div>
            <div className=''>
                <label>פירוט התקלה</label>
                <input value={values.fault} type="text" name="fault" placeholder="פרט מה הלקוח מספר" onChange={(e)=>{setValues({...values, fault: e.target.value})}}/>

            </div>
            <div className=''>
                <h4>אחרי השיחה עם הלקוח אנא סמן מה התקלה</h4>
                <select value={values.faultType} name="faultType" placeholder='fault' type="text" onChange={e => setValues({...values, faultType:e.target.value})}>
                    <option value="">בחר תקלה</option>
                    <option value="דלתות" >דלתות</option>
                    <option value="עומדת" >עומדת - לא מקבלת קריאות</option>
                    <option value="רעשים">רעשים</option>
        
    </select>
            </div>
            <button className='btn btn-success' type='submit'>שמור</button>
            </form>
    </div>

                            
                         
    )
}
export default FaultCall