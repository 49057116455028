import React, { useEffect, useState } from "react";
import "../../App.css";
import "./Services.css";

import {useLocation} from 'react-router-dom'


function Services() {

  const id = useLocation().state

  const [openAccordion, setOpenAccordion] = useState(null);

  useEffect(() => {
      
    const element = document.getElementById(id);
    element && element.scrollIntoView({ behavior: "smooth"});
    handleAccordionClick(id)
  }, [id]);

  const handleAccordionClick = (index) => {
    if (index !== openAccordion) {
        setOpenAccordion(index);
     } else {
       setOpenAccordion(null);
    }
  };
  
  return (
    <div className="services">

      <h1 className="title-service">שרותינו </h1>
      

      <div className="accordion_container">
        <div className={`accordion_item ${openAccordion === 0 ? "open" : "" }`}>
          <div className='header' onClick={()=> handleAccordionClick(0)}>
            
              <h4 className="title" id={0}>התקנת כל סוגי המעליות </h4>
              {openAccordion ===0 ? <i className="bi bi-chevron-up"></i> :<i className="bi bi-chevron-down"></i>}
          </div>
        
            <div className="accordion-body">
              <p>
                ישנו מגוון רחב של סוגי מעליות בשוק.
                <br />
                סוגי המעליות:{" "}
              </p>
              <p>
                1. <b> מעלית הידראולית</b> – מעליות הידראוליות נחשבות לפתרון
                אמין ואיכותי בבניינים קיימים או בבתים פרטיים. המעלית מתאימה
                לבניינים של עד 6 קומות או 7 תחנות.
                <br />
                המעלית נעה במהירות ממוצעת של 0.62 מטר לשנייה וניתן גם במהירות של
                עד 1.0 מ'/ש'.
                <br />
                מנגנון ההפעלה: מבוסס על משאבה חשמלית ובוכנה הידראולית אשר
                ממוקמות: מתחת למעלית או לצד המעלית והמשאבה יכולה להיות ממוקמת
                בחדר מכונות או בארון מיוחד הצמוד לפיר או בסמיכות לו.
                <br />
                מעלית הידראולית מאפשרת התקנת תאי מעלית גדולים יותר ממעלית חשמלית
                קונבנציונלית בשל ניצול אופטימלי של שטח הפיר, משום שאין צורך
                במשקל נגדי. אלו נקראות-מעליות מסוג מלגזה. כיום יש גם מעליות MRL
                שעובדות כך, ברם מעלית הידראולית עם מנגנון תקיפה ישירה מלמטה
                מאפשר את מידת הניצול המקסימלית בין שטח הפיר לגודל התא. כך שפיר
                קטן מידות מאפשר את התא הגדול ביותר מכל שיטות ההנע למעליות.
                <br />
                2. <b>מעלית חשמלית </b> – מעליות חשמליות מתאימות לכל הגבהים.
                <br />
                כל מעלית נעה במהירות שונה החל מ- 0.8 ועד 2.5 מטר לשנייה וגם יותר
                וזה בהתאם למספר הקומות בבניין.
                <br />
                מנגנון ההפעלה: מבוסס על מנוע וגיר חשמליים שנמצאים בחדר מכונות
                והם מסובבים את גלגל ההינע. חדר מכונות מעל הפיר מבזבז שטח לבניין
                ולא מאפשר ניצול של כל הגג.
                <br />
                3.<b> מעלית MRL= Machine Room Less </b> - מעליות MRL הן מעליות
                חשמליות שאינן מצריכות חדר מכונות, מה שחוסך מקום. מעלית MRL מהירה
                יותר ממעלית הידראולית והנסיעה בה חלקה ככה שכמעט לא מרגישים
                עצירה. מעליות אלו הן בפסגה הטכנולוגית של תחום המעליות. ההינע
                שלהן הוא על פי רוב מנוע מודרני תלת פאזי ללא גיר והמופעל ע"י בקר
                תדר משוכלל המאפשר נסיעה כמעט מושלמת ועצירה נוחה מדויקת ובעיקר
                בלתי מורגשת בתחנות.
                <br />
                מערכת ההפעלה: מנוע המעלית ממוקם בתוך חלקו העליון של הפיר ולעיתים
                על הפסים שעליהם נעה המעלית.
                <br />
                המעלית הזו היא חסכונית באנרגיה, המנועים קטנים ביחס לעומס המבוקש-
                מתאימה לבניין עם יותר מ-5 קומות וקיימת בה מערכת גיבוי לחילוץ
                אוטומטי במקרה של הפסקת חשמל.
                <br />
                אמנם זאת מעלית חשמלית עם משקל נגדי שגוזל שטח מהפיר אולם כיום יש
                מעליות ללא חדר מכונה העובדות כמעליות מלגזה כך שניצול השטח ביחס
                לגודל התא משתפר כמו במעליות ההידראוליות.
                <br />
                המהירות של המעלית נקבעת לפי מספר הקומות בבניין החל מ- 1.0 עד 2.5
                מטר לשנייה ואף מהר יותר.
                <br />
                4. <b>מעלון </b>- או מעלית לנכים כהגדרה בתקן המאפשרת תא גדול
                יותר מהיחס המקובל במעליות בין גודל התא והעומס המורשה. נניח מעלון
                לעומס שלושה נוסעים יכול להיות בגודל של שמונה נוסעים.
                <br />
                שזה יתרון גדול.
                <br />
                מעלונים מתאימים לשינוע נוסעים לגבהים קטנים ומתאימים לפנטהאוזים,
                וילות דופלקסים ובתים פרטיים. החל ממספר בודד של מדרגות ועד ל-3
                תחנות.
                <br />
                מעלון לא זקוק לפיר, המהירות בו היא איטית ומחירו נמוך משמעותית
                ממעלית.
                <br />
                כשאין בור בתחתית הפיר ניתן להתקין מעלית ביתית כזאת גם בפנטהאוזים
                ובדירות מגורים בבנייני מגורים רגילים.
                <br />
                במעלון בדרך כלל אין דלת תא פנימית ורק דלתות פיר חיצוניות קיימות.
                <br />
                מהירות הנסיעה 0.15 מ'\ש'.והנסיעה בלחיצה קבועה על לחצן הנסיעה.
                <br />
                המעלונים ע"פ רוב בהינע הידראולי ובמנוע חד פאזי שזה גם יתרון.
                <br />
              </p>
            </div>
         
        </div>

        <div className={`accordion_item ${openAccordion === 1 ? "open" : "" }`}>
          <div className='header' onClick={()=> handleAccordionClick(1)}>
            
              <h4 className='title' id={1}>בניית פיר מעלית </h4>
              {openAccordion ===1 ? <i className="bi bi-chevron-up"></i> :<i className="bi bi-chevron-down"></i>}
          </div>
          
            <div className="accordion-body">
              <p>
                כל מעלית נמצאת בתוך פיר שנבנה במיוחד עבורה. יש פיר חיצוני ופיר
                פנימי ובלבד שהיציאה והכניסה למעלית תהיה ממבוא המאפשר יציאה
                לרחוב, תמיד. יציאה למבוא חסום אסורה על פי חוקי הבניה בישראל.
                <br />
                הפיר חייב להיות ע"פ תקנות הבניה ולפי אישורי התקינה של כיבוי האש
                ומתאים לכל תנאי מזג האוויר כדי לשמור על המעלית של תפגע.
                <br />
                תכנון ובניית פיר מעלית מצריך חשיבה חכמה ויצירתית.
                <br />
                אנשי צוות מעולים ומנוסים מגיעים למקום לבדוק איפה תמוקם המעלית
                שיהיה הכי נוח, יפה ומתאים לכולם.
                <br />
                פיר חיצוני – פיר המעלית ימוקם מחוץ למבנה אבל עם קשר לחדר
                המדרגות.
                <br />
                פיר פנימי – ימוקם בתוך חדר המדרגות או בתוך הדירה בפנטהאוזים
                ובבתים פרטיים.
              </p>
            
          </div>
        </div>

        <div className={`accordion_item ${openAccordion === 2 ? "open" : "" }`}>
          <div className='header' onClick={()=> handleAccordionClick(2)}>
            
              <h4 className='title' id={2}> הוצאת היתרי בנייה </h4>
              {openAccordion ===2 ? <i className="bi bi-chevron-up"></i> :<i className="bi bi-chevron-down"></i>}
          </div>
          
            <div className="accordion-body">
              <p>
                כל התקנת מעלית דורשת היתר בנייה מהרשות המקומית. חברת מעוף מעליות
                מגישה בשמכם בקשה לקבלת היתר בנייה.
                <br />
                זמן קבלת ההיתר משתנה מרשות לרשות.
                <br />
                בממוצע בין 4 ל-6 חודשים ולפעמים אפילו יותר.
              </p>
            </div>
         
        </div>

        <div className={`accordion_item ${openAccordion === 3 ? "open" : "" }`}>
          <div className='header' onClick={()=> handleAccordionClick(3)}>
            
              <h4 className='title' id={3}>שינוי מהלך מדרגות </h4>
              {openAccordion ===3 ? <i className="bi bi-chevron-up"></i> :<i className="bi bi-chevron-down"></i>}
          </div>
         
            <div className="accordion-body">
              <p>
                אחרי קביעת מיקום הפיר יש צורך לפעמים לעשות שינוי מהלך מדרגות כדי
                לאפשר מקום נוח למעלית.
                <br />
                בכל פרויקט המחייב שינוי מהלך המדרגות, הוא שונה וייחודי לאותו
                מקום. מאפשר להיות יותר גמישים במיקום המעלית בצורה הטובה ביותר.
              </p>
            </div>
          
        </div>

        <div className={`accordion_item ${openAccordion === 4 ? "open" : "" }`}>
          <div className='header' onClick={()=> handleAccordionClick(4)}>
            
              <h4 className='title' id={4}>תכנון וייצור מעליות </h4>
              {openAccordion ===4 ? <i className="bi bi-chevron-up"></i> :<i className="bi bi-chevron-down"></i>}
          </div>
          
            <div className="accordion-body">
              <p>
                אחרי שהבנו איפה המעלית הולכת להיות ממוקמת וכמה שטח יש לנו, מעוף
                מעליות מתחילה לתכנון ולייצר מעלית ייחודית ויפה לפי המידה
                הנוכחית, שתתאים בדיוק ותהיה מנוצלת מבחינת שטח.
                <br />
                ייצור המעליות שלנו בחברות האירופאיות האיכותיות ביותר. רמה עיצוב
                גבוהה ביותר, כל מעלית אפילו הסטנדרטית יכולה לפאר בתי מלון
                ובנייני משרדים.
                <br />
                ריצוף אבן טבעית, בתא המעלית. גימור התא בנירוסטה מרוקעת ותקרה
                מעוצבת עם לדים לתאורה מתקדמת.
                <br />
                פנל לחצני תא לגובה כל התא ומנירוסטה מוברשת, כנ"ל פנלי לחצני החוץ
                מנירוסטה משובחת ואסטטית.
              </p>
            </div>
          
        </div>

        <div className={`accordion_item ${openAccordion === 5 ? "open" : "" }`}>
          <div className='header' onClick={()=> handleAccordionClick(5)}>
              <h4 className='title' id={5}>הוספת מעלונים </h4>
              {openAccordion ===5 ? <i className="bi bi-chevron-up"></i> :<i className="bi bi-chevron-down"></i>}
          </div>
            <div className="accordion-body">
              <p>
                בבתים פרטיים – וילות, דופלקסים, פנטהאוזים אנחנו יכולים לשפר את
                איכות חייכם בין אם אתם מבוגרים, נכים או צעירים הרוצים נוחות
                ותכנון המרחב באופן המתקדם והמודרני שגם משביח את הנכס שלכם. ניתן
                להנגיש במחיר סביר. מעלון נראה כמו מעלית אבל הנסיעה בו היא יותר
                איטית וללא חדר מכונות. פתרון נהדר.
                <br />
                ניתן להתאים את עיצוב המעלון לעיצוב של הבית.
              </p>
            </div>
          
        </div>

        <div className={`accordion_item ${openAccordion === 6 ? "open" : "" }`}>
          <div className='header' onClick={()=> handleAccordionClick(6)}>
           
              <h4 className='title' id={6}>כל ההתקנות בפיקוח מכון התקנים </h4>
              {openAccordion ===6 ? <i className="bi bi-chevron-up"></i> :<i className="bi bi-chevron-down"></i>}
          </div>
            <div className="accordion-body">
              <p>
                מעלית היא מוצר שמחייב תכנון יצור והרכבה לפי תקן ישראלי. <br />
                חברה המייצרת מעליות חייבת להיות מאושרת ע"י מכון התקנים גם בחלקי
                המעלית וגם בתהליכי הייצור וההרכבה.
                <br />
                רק חברה מורשה על ידי מכון התקנים רשאית להתקין מעליות ו\או
                מעלונים.
                <br />
                כל מעלית חדשה חייבת להיבדק בדיקה ראשונית ע"י מכון התקנים, בלי
                בדיקה זו המעלית אסורה לשימוש. רק אחרי אישור מכון התקנים שהמעלית
                עומדת בכל הדרישות והבדיקות – המעלית ניתנת לשימוש.
                <br />
                * פעם בחצי שנה מתבצעת בדיקת בטיחות של המעלית ע"י בודק מעליות
                מוסמך.
                <br />
                בדיקת המהנדס החצי שנתי בדומה לטסט השנתי לרכב.
              </p>
            </div>
        
        </div>

        <div className={`accordion_item ${openAccordion === 7 ? "open" : "" }`}>
          <div className='header' onClick={()=> handleAccordionClick(7)}>
            
              <h4 className='title' id={7}>מתן שירות למעליות </h4>
              {openAccordion ===7 ? <i className="bi bi-chevron-up"></i> :<i className="bi bi-chevron-down"></i>}
          </div>
          
            <div className="accordion-body">
              <p>
                צוות טכנאים של מעוף מעליות מגיע לבדיקות שגרתיות לפחות 6 פעמים
                בשנה.
                <br />
                הצוות בודק את תקינות כל המכלולים והפיקוד של המעלית או
                המעלון.טיפול זה נקרא טיפול מונע ומשמר.
                <br />
                השאיפה שלנו בשירות שמלבד ההגעה המתוכננת שלנו לטיפול מונע לא
                יזמנו אותנו לתקלות המתרחשות במערכות מכניות.ואכן טיפול מונע קפדני
                מונע תקלות עתידיות ומאפשר נסיעה רציפה במעליות ללא תקלות הנובעות
                מהזנחה.
                <br />
                חשוב לנו שתפעול המערכות יהיה רציף וללא תקלות.
              </p>
            </div>
         
        </div>

        
      
      </div>
    </div>
  );
}
export default Services;
