import React from 'react'
import '../../App.css'
import './Home.css'
import '../Accessibility.css'
import {HashLink} from "react-router-hash-link"


function Home(){
    
    return(
       <div>
        <div className="home">
            <div className='scroll-container'>
                <p className='scroll-text'>
                    חברת המעליות  - מעוף מעליות מציעה שירות אדיב, אמין ומקצועי עם התקנת מעליות איכותיות וברמה גבוהה. מוזמנים להתרשם! 
                </p>

            </div>
            <div className='column'>
                <div className='part2'>
                    <h1 className='title-home'>מעוף מעליות</h1>
                    <h2 className='text-home'>  מתמחה בהתקנה והוספת מעליות או מעלונים בבניינים קיימים ובתים פרטיים. <br/> הוספת מעליות ביתיות בפנטהאוזים.</h2>
                </div>
                <div className='part1'>
                    <img className='photo-home' src='/elevator-1.webp' alt='homepage'/>
                </div>
                
                
            </div>
            
            <div className='column1'>

                <h2 className='advantages-title'>4 יתרונות להוספת מעלית</h2>
                <div className='advantages'>
                    <div className='advantage'>
                        <p>
                    1. עליית ערך הדירה בשיעורים שבין 30%-15%
                    </p></div>
                    <div className='advantage'><p>
                    2. נוחות ואיכות חיים משודרגת וגבוהה יותר
                    </p></div>
                    <div className='advantage'><p>
                    3. חיזוק יסודות הבניין כחלק מעבודות ההתקנה
                   </p> </div>
                    <div className='advantage'><p>
                    4. דירה אטרקטיבית יותר לרוכשים פוטנציאליים
                    </p></div>
                </div>
                </div>
                <div className='column2'>
                    <h2 className='service_title'> שירותי חברת מעוף מעליות</h2>
                    <div className='grid-services'>
                        <div className='service'>
                            <HashLink className='text-service' to="/שרותים">התקנת מעליות</HashLink>
                        </div>
                        <div className='service'>
                            <HashLink className='text-service' to="/שרותים#panelsStayOpen-headingTwo" >בניית פיר מעלית</HashLink>
                        </div>
                        <div className='service'>
                            <HashLink className='text-service' to="/שרותים#panelsStayOpen-collapseTwo">הוצאת היתר בנייה</HashLink>
                        </div>
                        <div className='service'>
                            <HashLink className='text-service'to="/שרותים#panelsStayOpen-collapseThree">שינוי מהלך מדרגות</HashLink>
                        </div>
                        <div className='service'>
                            <HashLink className='text-service' to="/שרותים#panelsStayOpen-collapseFour">תכנון וייצור מעליות</HashLink>
                        </div>
                        <div className='service'>
                           <HashLink className='text-service' to="/שרותים#panelsStayOpen-collapseFive">הוספת מעלונים</HashLink>
                        </div>
                        
                       <div className='serv1 justify-content-evenly'>
                            <HashLink to="/שרותים#panelsStayOpen-collapseSeven" className='text-service'>מתן שירות אחזקת מעליות</HashLink>
                        </div>

                    </div> 
                        
                    </div>
                    <div className='column3'>
                        <h2 className='service_title'>קבצים להורדה</h2>
                        <div className='documents-list'>
                            <a className='document' href={require('../../documents/acoustics1.pdf')}>אקוסטיקה1</a>
                            <a className='document' href={require('../../documents/acoustics2.pdf')}
        >אקוסטיקה2</a>
                            <a className='document' href={require('../../documents/Precautions.pdf')}>הוראות בטיחות לעבודה</a>
                            <a className='document' href={require('../../documents/SteelRopes.pdf')}>חבלי פלדה במעלית</a>
                            <a className='document' href={require('../../documents/Introduction blocked.pdf')}>מבוא חסום</a>
                            <a className='document' href={require('../../documents/Hydraulic Sabbath.pdf')}>מפרט שבת-הידראולית</a>
                            <a className='document' href={require('../../documents/electric sabbath.pdf')}>מפרט שבת- חשמלית</a>
                            <a className='document' href={require('../../documents/fall protection equipment.pdf')}>ציוד נגד נפילה</a>
                            <a className='document' href={require('../../documents/firemen.pdf')}>תוספת מפתח כבאים</a>
                            <a className='document' href={require('../../documents/Standard for the disabled.pdf')}>תקן לנכים פנים המבנה</a>
                            <a className='document' href={require('../../documents/Construction regulations.pdf')}>תקנות בנייה</a>
                        </div>
                    </div>
            </div>
            
            </div>
    );
}

export default Home;