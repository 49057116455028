import React from 'react'
import '../../App.css'
import './Projects.css'
import Slideshow from '../SlideShow'
import { gallery } from '../../gallery'

import Carousel from '../Carousel'


function Projects() {
  
 
    return(
      <div className="projects">
        <h1 className='title-service'>הגלריית התמונות שלנו</h1>
        <div className='container-projects'>
          <div className='gallery-grid'>
            {gallery.map((p,index)=>{
              return(
              <div className='gallery-card' key={index}>
                <h3 >{p.title}</h3>
                <Slideshow photos={p.photos}/>
                <h6 className='description'>{p.description}</h6>
                
              </div>
              )
            })}
          </div>
        
        </div>
        <div className="carousel-in-project">
          <h3 className="title-more-imgs">עוד קצת תמונות של המעלונים שלנו</h3>
          <Carousel/>
        </div>
        
        
      </div>

    );
}
export default Projects
