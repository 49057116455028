export const gallery = [
    {
        title: "בית פרטי-ראשון לציון",
        description: "הוספת מעלון כולל פיר זכוכית בבית פרטי",
        photos: ['/projects/rshlaz/rshlz.jpeg',
        '/projects/rshlaz/rshlz1.jpeg',
         '/projects/rshlaz/rshlz2.jpeg',
        '/projects/rshlaz/rshlz3.jpeg',
       '/projects/rshlaz/rshlz4.jpeg']
    }, 
    {
        title:"בית משותף - שד' בן ציון, תל אביב",
        description: "הוספת מעלית בבניין קיים, בתוך חדר המדרגות. חדר המדרגות במסגרת אגף השימור.",
        photos: ['/projects/bentzion/bentzion1.jpeg','/projects/bentzion/bentzion2.jpeg', '/projects/bentzion/bentzion3.jpeg']
    },
    {
        title:"בית פרטי - ראשון לציון",
        description:"הוספת מעלית פנורמית בבית פרטי",
        photos: ['/projects/mendel/mendel1.jpeg','/projects/mendel/mendel2.jpeg', '/projects/mendel/mendel3.jpeg','/projects/mendel/mendel4.jpeg', '/projects/mendel/mendel5.jpeg']
    },
    {
        title:"בית כנסת - פתח תקווה",
        description:"מעלית חיצונית מונגשת כולל פיר וגשר",
        photos: ['/projects/petahTiqwa/petahTiqwa1.jpeg', '/projects/petahTiqwa/petahTiqwa2.jpeg','/projects/petahTiqwa/petahTiqwa3.jpeg','/projects/petahTiqwa/petahTiqwa4.jpeg']
    },
 
]