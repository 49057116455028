import React, { useState } from "react";
import {collection,  doc, setDoc, getDocs,deleteDoc} from 'firebase/firestore'
import {db} from '../firebase-config'
import Popup from './Popup'
import Visit from "./Visit";
import FaultCall from "./FaultCall";
import {useNavigate}from 'react-router-dom'
import 'bootstrap-icons/font/bootstrap-icons.css'

function UserCard({id, date, count, visitTime, nextVisit}){
    const [popDelete, setPopupDelete] = useState(false)
    const [popVisit, setPopVisit] = useState(false)
    const [popFault, setPopFault] = useState(false)
    const navigate = useNavigate()
    
    const deleteProject=()=>{
        
        deleteDoc(doc(db, "serviceTracking", id));
        
        setPopupDelete(!popDelete)
        
    }
    const toggle =()=>{
        if(popDelete){
            setPopupDelete(!popDelete)
        }
        else if(popVisit){
            setPopVisit(!popVisit)
        }
        else {
            setPopFault(!popFault)
        }

        
    }
    //date.split('-').reverse().join('/')
    const nextTime = new Date(date)
    
    nextTime.setDate(nextTime.getDate() + parseInt(visitTime))


    return(
        <div className="card p-3" style={{"width": "150%", "height": "350px"}}>
            <div className="btn-group w-10 d-flex mr-auto">
                <button className="btn  btn-sm " type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className="bi bi-three-dots icon-three-dots"/>
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a className="dropdown-item" onClick={()=>{setPopVisit(true)}}>הוסף ביקור</a>
                    <a className="dropdown-item" onClick={()=>{setPopFault(true)}}>קריאה לתקלה</a>
                    <a className="dropdown-item" onClick={()=>{setPopupDelete(true)}} >מחק</a>
                </div>
            </div>
            {/* <div className="d-flex flex-row-reverse">
                <button className="btn btn-dark" onClick={()=>{setPopupDelete(true)}} >מחק</button>
            </div> */}
            <h3 className="text-center " onClick={()=>{navigate(`/קריאה ותקלה/${id}`)}}>{id}</h3>
            <p><strong>תאריך ביקור אחרון:</strong> {date}</p>
            <p><strong>זמן ביקור: </strong>{visitTime} ימים</p>
            <p><strong>ביקור הבא:</strong> {nextVisit }</p>
            <p><strong>סה"כ ביקורים השנה:</strong> {count}</p>
            <div className="d-flex justify-content-around">
                {/* <button className="btn btn-success" onClick={()=>{setPopVisit(true)}}>ביקור</button>
                <button className="btn btn-danger" onClick={()=>{setPopFault(true)}}>קריאה לתקלה</button> */}
            
            </div>
            <div>
                {popDelete && <Popup handleClose={toggle}
                            content={
                            <div>
                                <h3>אתה בטוח רוצה למחוק?{id}</h3>
                                <div className="d-flex justify-content-around">
                                    <button className="btn btn-outline-success" onClick={deleteProject}>כן</button>
                                    <button className="btn btn-outline-danger" onClick={toggle}>לא</button>
                                </div>
                             </div>
                             }/>}
                             
                
                    
            </div>
<div>{popVisit && <Popup handleClose={toggle} content={<Visit id={id} close={toggle}/>}/>}</div>
<div>
            {popFault && <Popup handleClose={toggle} content={<FaultCall id={id} close={toggle}/>}/> }
        </div>
        </div>
        

    )
}
export default UserCard;