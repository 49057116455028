export const about = [
    {
        title:"ניסיון",
        descrip: "מעוף מעליות הינה חברה משפחתית בעלת יותר מ-30 שנות ניסיון ומוניטין בייצור, בעיצוב ובהתקנה של מעליות.",
        icon: "bi bi-trophy"
    },
    {
        title:"תפירה אישית",
        descrip: "אנשי המקצוע שלנו מומחים בתפירה אישית של  המעלית הכי כדאית למקום ולכן כל מעלית מיוצרת בהתאמה יחודית ומדויקת לדרישות הלקוח. ",
        icon:"bi bi-fingerprint"
    },
    {
        title: "יחס אישי",
        descrip : "היחס האישי אצלנו מבטיח טיפול צמוד ומקצועי בכל פרויקט - לשביעות רצון כל הלקוחות. הצוות שלנו עושה את המרב והמיטב ומגשימים את החלום של הלקוח כל פעם מחדש.",
        icon: "bi bi-hand-thumbs-up"
    },
    {
        title:"ייעוץ והדרכה",
        descrip: "השירות כולל ייעוץ והדרכה, ליווי מקצועי צמוד של ועד הבית, ניסוח פרוטוקול החלטות והנפקת היתרי בנייה ממהנדסים, מיועצים ומעירייות.",
        icon: "bi bi-briefcase"
    },
    {
        title:"סטנדרט גבוה",
        descrip: "מעוף מעליות מייצרת מעליות ברמה גבוהה, תוך שימוש ברכיבים איכותיים. רכיבי המעליות של מעוף מעליות מגיעים מהחברות הגדולות ביותר בתחום, דבר המאפשר גמישות בתכנון, בייצור ובהתקנה.",
        icon: "bi bi-rocket-takeoff"
    },
    {
        title:"אחריות",
        descrip: "לאחר ההתקנה, אנשי המקצוע שלנו מסםקים הסבי שימוש ותחזוקה מונעת - החברה נותנת אחריות בסיסית לשנתיים מתום ההתקנה. מעוף מעליות מחוייבת למתן שירות מקצועי לאורך כל הדרך, ומן היתרונות נהנים הלקוחות.",
        icon: "bi bi-emoji-grin"
    },
]