import React from 'react'
import '../../components/pages/Videos.css'
function Videos() {
   // Set a same-site cookie for first-party contexts
document.cookie = 'cookie1=value1; SameSite=Lax';
// Set a cross-site cookie for third-party contexts
document.cookie = 'cookie2=value2; SameSite=None; Secure';
  return (
    <div className='videos'>
        <h1 className='title-service'>הגלריית הסרטונים שלנו</h1>
        <div>
            <div>
                 <iframe className="video" title='complete-elevator' src={`https://www.youtube.com/embed/rKS3yWZTbW4`}></iframe>
            </div>
           <div>
                <iframe  className="video" title='shaft-construction' src={`https://youtube.com/embed/xXUzreziUn4`}></iframe>
           </div>
           
           
        </div>
        
    </div>
  )
}

export default Videos