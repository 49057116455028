import React,{useEffect, useState} from 'react'
import '../../App.css'
import './User.css'
import 'bootstrap/dist/css/bootstrap.css'
import {useLocation} from 'react-router-dom'

// import '@react-pdf-viewer/core/lib/styles/index.css';
// import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import Logout from '../Logout'


function User(){
    
    const projectNum = useLocation().state

    const [values, setValues] = useState({
        projectName: '',
        dateSignature: '',
        elevatorType: '',
        endDate: '',
        planning: '',
        procurement: '',
        workPlan:'',
        electricCompany: '',
        standardsInstitute: '',
        receivElevator: ''
    })

    useEffect(() => {
      fetch(`https://server-maof.onrender.com/project/get-all-project-data?projectNum=${projectNum}`,{
        method: 'GET',
        headers: {
          "Access-Control-Allow-Origin": "*",
            'Content-Type': 'application/json',
            "Accept": "application/json"}
  
      }).then(response=>response.json()).then((data)=>{
        if(data.status==='error'){
          console.log(data.message)
          return alert("יש בעיה")
        }
        setValues(data.data[0])
      })
   

    },[])
    const onlyDate =(dateTime)=>{
      if(dateTime!= null){
          const [date,] = dateTime.split('T')
          return date
      }
      
  }
   

    return (
        
        <div className='user'>
            

            <Logout/>
            <h3>תהליך התקנת מעלית</h3>
           <div>
            <h6><strong>כתובת: </strong>{values.projectName}</h6>
            <h6><strong>סוג מעלית: </strong> {values.elevatorType}</h6>
            <h6></h6>
           </div>
      <div className="container">
          <article className="card">
              <div className="card-body">
                <div className="track">
                  <div className={values.dateSignature ? "step active" : "step"}> 
                  <span className="icon"><i className="fa fa-edit"></i>
                  </span>
                   <span className="text">חתימת חוזה</span>
                    </div>
                    <div className={values.planning?"step active" : "step "}> <span className="icon"><i className="fa fa-users"></i>
                    </span> 
                    <span className="text"> תכנון מול ספקים</span> 
                    </div>
                      <div className={values.procurement ?"step active" : "step "}> <span className="icon"> <i className="fa fa-shopping-cart"></i></span> <span className="text"> רכישת חלקים</span> </div>
                      <div className={values.workPlan ?"step active" : "step "}> <span className="icon"><i className="fa fa-list-ol"></i>  </span> <span className="text"> תוכנית עבודה</span> </div>
                      <div className={values.electricCompany ?"step active" : "step "}> <span className="icon"><i className="fa fa-bolt"></i> </span> <span className="text">חברת חשמל</span> </div>
                      <div className={values.standardsInstitute ? "step active" : "step "}> 
                      <span className="icon ">
                      <i className="fa fa-user"></i> </span> 
                        <span className="text">מכון התקנים</span> 
                        </div>
                      <div className={values.receivElevator ?"step active" : "step "}> 
                      <span className="icon"> <i className="fa fa-check"></i> </span> <span className="text">קבלת המעלית</span> </div>
                  </div>
          </div>


          
          </article>
          </div>
          <div className="explan">
            <ul className="box">
              <li className={values.receivElevator ?"show_step" : "no_show_step "}>
                <p>{onlyDate(values.receivElevator)}</p>
                <h5>ביצוע תדרוך לקבלת המעלית</h5>
              </li>
              <li className={values.standardsInstitute ?"show_step" : "no_show_step "}>
                <p>{onlyDate(values.standardsInstitute)}</p>
                <h5>עברנו בהצלחה את הבדיקה של מכון התקנים</h5>
              </li>
              <li className={values.electricCompany ?"show_step" : "no_show_step "}>
                <p>{onlyDate(values.electricCompany)}</p>
                <h5>קבלת טופס 4 מחברת החשמל</h5>
              </li>
              <li className={values.workPlan ?"show_step" : "no_show_step "}>
                
                <h5>בניית תוכנית עבודה ותחילתה</h5>
              </li>
              <li className={values.procurement ?"show_step" : "no_show_step "}>
                <h5>רכישת חלקי המעלית - ייבוא</h5>
              </li>
              <li className={values.planning ?"show_step" : "no_show_step "}>
                
                <h5>תכנון המעלית מול ספקים</h5>
              </li>
              <li className={values.dateSignature ?"show_step" : "no_show_step "}>
                <p>{onlyDate(values.dateSignature)}</p>
                <h5> חתימת חוזה </h5>
              </li>
              
            </ul>
          </div>
            
            
        </div>
    );
    

}

export default User