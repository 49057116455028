import React ,{ useState } from 'react';
import './Navbar.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
//import {Button} from './Button.css'
import {Link ,useNavigate} from 'react-router-dom';
import 'bootstrap-icons/font/bootstrap-icons.css'

function Navbar() {
  
  const navigate = useNavigate()
  
  const [click, setClick] = useState(false);
  //const [button, setButton] = useState(true)

  const handleClick = () => setClick(!click);
  const closeMobileMenu =() => setClick(false);
  
  const backHome = () =>{
    navigate("/")
  }
    

    return (
        <>
         <nav className="Navbar">
              <div className="navbar-container">
                
                   <img src="/new-logo2.png" className="maof-logo" alt="logo" onClick={backHome}/>  
                 
                <div className='menu-icon' onClick={handleClick}>
                  <i className={click ? 'bi bi-x' : 'bi bi-list'}/>
                </div>
                
                 
                <ul className={click? 'nav-menu active' : 'nav-menu'}>
                
                    <li className='nav-item'>
                      <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                         דף הבית
                      </Link>
                    </li>
                     
                    
                    <li className='nav-item'>
                      {/* <Link to='/פרויקטים' className='nav-links' onClick={closeMobileMenu}>
                        פרויקטים
                      </Link> */}
                      <div className="btn-group w-10 d-flex mr-auto">
                      <button className="btn" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                     <Link className='nav-links'>פרויקטים</Link>
                </button>
                     
                        <div className='dropdown-menu' aria-labelledby="dropdownMenuButton">
                          <Link to='/פרויקטים' className="dropdown-item" onClick={closeMobileMenu}>תמונות</Link>
                          <Link to='/סרטונים/פרויקטים' className="dropdown-item" onClick={closeMobileMenu}>סרטונים</Link>
                        </div>
                      </div>
                    </li>
                    <li className='nav-item'>
                      <Link to='/התהליך' className='nav-links' onClick={closeMobileMenu}>
                        התהליך
                      </Link>
                    </li>
                    <li className='nav-item'>
                      <Link to='/services' className='nav-links' onClick={closeMobileMenu}>
                         שרותים
                      </Link>
                    </li>
                    <li className='nav-item'>
                      <Link to='/אודות'  className='nav-links' onClick={closeMobileMenu}>
                        אודות
                      </Link>
                    </li>
                    <li className='nav-item'>
                      <Link to='/צור קשר' className='nav-links' onClick={closeMobileMenu}>
                        צור קשר
                      </Link>
                    </li>
                    <li className='nav-item'>
                 <Link to='/התחברות' className='nav-links' onClick={closeMobileMenu}><button className='btn btn-dark p-0'>
                          כניסת לקוח</button>
                      </Link>
                      
                    </li>
                    

                  </ul>
                  
                
                 
              </div>

         </nav> 
         
        </>
    );
}

export default Navbar
//  {button && <button buttonStyle='btn--outline' >כניסת לקוח</button>}
