import React,{useState, useEffect} from 'react'
import {collection, getDocs} from 'firebase/firestore'
import {db} from '../../firebase-config'
import './AllTreatments.css'
import '../../App.css'

function AllTreatments(){
    const [treats, setTreats] = useState([])

    useEffect(()=>{
        const getAllTreatments = async()=>{
            const data = await getDocs(collection(db,"treatments"))
            setTreats(data.docs.map((doc)=>({id:doc.id, charge: doc.data().charge, cost: doc.data().cost, fault:doc.data().fault, parts: doc.data().parts, solution: doc.data().solution, name: doc.data().name, date: doc.data().date })))

        }
        getAllTreatments()
    },[])

    return(
        <div className='allTreat'>
            <table className='tTreat'>
                <thead>
                    <tr>
                        <th>שם הפרויקט</th>
                        <th>תאריך</th>
                        <th>התקלה</th>
                        <th>פתרון</th>
                        <th>חלקים הוחלפו\נוספו</th>
                        <th>עלות</th>
                        <th>חיוב</th>
                    </tr>
                </thead>
                <tbody>
                    {treats.map((val)=>{
                        return(
                            <tr key={val.id}>
                                <td>{val.name}</td>
                                <td>{val.date}</td>
                                <td>{val.fault}</td>
                                <td>{val.solution}</td>
                                <td>{val.parts}</td>
                                <td>{val.cost}</td>
                                <td>{val.charge}</td>

                            </tr>
                        )
                    })}
                </tbody>
            </table>


        </div>
    )
}
export default AllTreatments