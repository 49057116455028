import React from 'react'
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

const handleDragStart = (e) => e.preventDefault();
const responsive = {
    10: { items: 1 },
    578: { items: 2 },
    1034: { items: 3 },

};
const items = [
	<img className="img-gallery" alt="img1" src="1.jpg"  onDragStart={handleDragStart} role="presentation" />,
	<img className="img-gallery" alt="img2" src="2.jpg" onDragStart={handleDragStart} role="presentation" />,
	<img className="img-gallery" alt="img3" src="3.jpg" onDragStart={handleDragStart} role="presentation" />,

    <img className="img-gallery" alt="img4" src="4.jpg" onDragStart={handleDragStart} role="presentation" />,
    <img className="img-gallery" alt="img5" src="5.jpeg" onDragStart={handleDragStart} role="presentation" />,
    <img className="img-gallery" alt="img6" src="6.jpeg" onDragStart={handleDragStart} role="presentation" />,
    <img className="img-gallery" alt="img7" src="7.jpeg" onDragStart={handleDragStart} role="presentation" />,
    <img className="img-gallery" alt="img8" src="8.jpeg" onDragStart={handleDragStart} role="presentation" />,
    <img className="img-gallery" alt="img9" src="9.jpg" onDragStart={handleDragStart} role="presentation" />,
    <img className="img-gallery" alt="img10" src="10.jpg" onDragStart={handleDragStart} role="presentation" />,
    <img className="img-gallery" alt="img11" src="11.jpg" onDragStart={handleDragStart} role="presentation" />,
    <img className="img-gallery" alt="img12" src="12.jpg" onDragStart={handleDragStart} role="presentation" />,
    <img className="img-gallery" alt="img13" src="13.jpg" onDragStart={handleDragStart} role="presentation" />,
    <img className="img-gallery" alt="img14" src="14.jpg" onDragStart={handleDragStart} role="presentation" />,
    <img className="img-gallery" alt="img15" src="15.jpg" onDragStart={handleDragStart} role="presentation" />,
    
];
function Carousel() {
  // Set a same-site cookie for first-party contexts
document.cookie = 'cookie1=value1; SameSite=Lax';
// Set a cross-site cookie for third-party contexts
document.cookie = 'cookie2=value2; SameSite=None; Secure';
  return (
    <AliceCarousel mouseTracking items={items} controlsStrategy='alternate' responsive={responsive}/>
  )
}

export default Carousel