import React,{useState, useEffect} from 'react'
import { collection,  getDocs} from 'firebase/firestore'
import {db} from '../../firebase-config'
import './AllCalls.css'

function AllCalls(){
    const [calls, setCalls] = useState([])

    useEffect(()=>{
        const getAllCalls = async()=>{
            const d = await getDocs(collection(db,"callsAndFaults"))
            setCalls(d.docs.map((doc)=>({id: doc.id, call: doc.data().call, date: doc.data().date, fault: doc.data().fault, name: doc.data().name})))
        }
        getAllCalls()
        console.log(calls)
    }, [])
    

    return(
        <div className='allCalls'>
            <table className='tCalls'> 
                <thead>
                    <tr>
                        <th>פרויקט</th>
                        <th>תאריך</th>
                        <th>קריאה</th>
                        <th>תקלה</th>
                    </tr>
                </thead>
                <tbody>
                    {calls.map((val)=>{
                        return(
                            <tr key={val.id}>
                                <td>{val.name}</td>
                                <td>{val.date}</td>
                                <td>{val.call}</td>
                                <td>{val.fault}</td>
                            </tr>
                        )
                        
                    }
                )
                    }
                    
                </tbody>
            </table>

        </div>
    )
}
export default AllCalls