import React, { useEffect, useState } from "react";
import {doc, setDoc, collection, onSnapshot} from 'firebase/firestore'
import {db} from '../firebase-config'

function Visit(props){
    const [values, setValues] = useState({
        date:'',
        typeTreatment: '',
        fault: '',
        solution: '',
        parts: '',
        cost: '',
        payment: '',
        call: '',
    })
    //30 or 60
    const [visitTime, setVisitTime ] = useState(0)
    const [call, setCall] =useState(false)
    const clickCall=async()=>{
        setCall(!call)
        setValues({...values, call: true})     
    }
    useEffect(()=>{
        const serCollectionRef = doc(db,"serviceTracking", props.id)
        onSnapshot(serCollectionRef, snap=>{
            setVisitTime(snap.data().visitTime)
        })
    },[])
    const save = async(e)=>{
        e.preventDefault()
        console.log(call)
        console.log(values.call)
        const nextVisit = new Date(values.date)
        nextVisit.setDate(nextVisit.getDate()+parseInt(visitTime))
        const nextVisit2 = nextVisit.toISOString().split('T')[0]
        console.log(nextVisit2)
        // Save in treatments
        if(values.call && values.solution){
            //create id
            const uuid = doc(collection(db,'treatments')).id
            //
            const treatCollection = doc(db,"treatments",uuid)
            await setDoc(treatCollection,{"cost":values.cost, "payment": values.payment, "fault": values.fault, "parts": values.parts, "solution": values.solution, "name": props.id, "date": values.date},{merge: true})
            //console.log(visitTime)
            const serCollectionRef = doc(db,"serviceTracking", props.id)
            setDoc(serCollectionRef, {"nextVisit":nextVisit2, "dates":{[values.date]: "טיפול בעקבות קריאה"}},{merge: true}).then(()=>{
                props.close()
            }).catch((err)=>console.log(err))
        }
        //seve in serviceTracking
        else{
            const serCollectionRef = doc(db, "serviceTracking", props.id)
            setDoc(serCollectionRef, {"nextVisit": nextVisit2, "dates": {[values.date]: values.typeTreatment}}, {merge: true}).then(()=>{
                props.close()
            }).catch((err)=>console.log(err))
        }
    }
    return(
        <div>
            <form onSubmit={save}>
                <div>
                    <label><strong>תאריך</strong></label>
                    <input value={values.date} type='date' placeholder="תאריך ביקור" name="date" onChange={e=>setValues({...values, date: e.target.value})}/>
                </div>
                <div className="d-flex m-4 align-items-center">
                    <h5>הגעת בגלל קריאה?</h5>
                    <button onClick={clickCall}  className="btn btn-outline-success m-2">כן</button>
                    <p>במידה ולחצת בטעות<button className="btn text-primary" onClick={()=>{setCall(!call)}}>לחץ כאן</button></p>
                    
                </div>
                {call ?
                        <div>
                            <div>
                                <label><strong>מה התקלה?</strong></label>
                                <input value={values.fault} type="text" name="fault" placeholder="הסבר" onChange={e=>setValues({...values, fault: e.target.value})}/>
                            </div>
                            <div>
                                <label><strong>הפתרון</strong></label>
                                <input value={values.solution} type="text" name="solution" placeholder="הסבר" onChange={e=>setValues({...values, solution: e.target.value})}/>
                            </div>
                            <div>
                                <label><strong>מה החלקים שנוספו או הוחלפו?</strong></label>
                                <input value={values.parts} type="text" name="parts" placeholder="לא חובה" onChange={e=>setValues({...values, parts: e.target.value})}/>
                            </div>
                            <div>
                                <label><strong>עלות החלקים</strong></label>
                                <input value={values.cost} type="number" name="cost" placeholder="אם לא הוחלפו או נוספו חלקים אין צורך לענות" onChange={e=>setValues({...values, cost: e.target.value})}/>
                            </div>
                            <div>
                                <label><strong>החיוב</strong></label>
                                <select value={values.payment} type="text" name="payment" onChange={e=>setValues({...values, payment: e.target.value})}>
                                            <option value="">בחר אופציה</option>
                                            <option value="בתשלום">בתשלום</option>
                                            <option value= "באחריות">באחריות</option>
                                            
                                </select>
                            </div>
                        </div>
                    : <div>
                        <label><strong>סוג טיפול:</strong></label>
                        <input value={values.typeTreatment} name="typeTreatment" type="text" placeholder="איזה סוג שירות נתת?" onChange={e=>setValues({...values, typeTreatment: e.target.value})} />
                        </div>}
                        <div className="d-flex justify-content-around m-3">
                            <button className="btn btn-success" type="submit"> שמור</button>
                        </div>
                
        </form>

        </div>

    )
}
export default Visit;