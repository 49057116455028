import React,{useState, useEffect} from 'react'
import {collection,  doc,  query, onSnapshot, where, getDocs, orderBy} from 'firebase/firestore'
import {db} from '../../firebase-config'
import './CallsFaults.css'
import {useParams} from 'react-router-dom'

function CallsFault(){
    let {name}= useParams();
    const [all, setAll]= useState([])
    const [services,setServices] = useState([])
    const [tmpSer, setTmpSer] = useState([])
    
    useEffect(()=>{
        
        
        // const getMappedValues=()=> {
        //     setServices(a)
        //     console.log("vv", services)
        //     services.map((val)=>(

        //         console.log(val.treat)
        //     ))
        // }
        
        getPlacesList()
        getServices()
        //getMappedValues()
        //getFoodData()
    },[])

    const getPlacesList =async()=>{
            
        const q = query((collection(db,"callsAndFaults")),where('name' ,'==' ,name))
        
        onSnapshot(q,(snapshot)=>{
            setTmpSer(snapshot.docs.map((doc)=>({id: doc.id , date:doc.data().date, name:doc.data().name, call: doc.data().call, fault: doc.data().fault})))
        })
        setAll(tmpSer.sort((a,b)=>{
            const aTime = new Date(a.date).getTime()
            const bTime = new Date(b.date).getTime()

            const aDelta = Math.abs(time - aTime)
            const bDelta = Math.abs(time - bTime)
            return (aDelta -bDelta)

        }))
        console.log("list",all)
        
    }
    const time = Date.now()
    const getServices =async()=>{

        const ref = doc(collection(db, "serviceTracking"),`/${name}`)
        // const h = query(ref, orderBy("date"))
        // const d = await getDocs(h)
        // console.log(d)
        const tmp = []
        onSnapshot(ref, (snap) => {
            if (!snap.empty) {
                const data = snap.data()
                
                 const len = Object.values(data.dates).length
                 console.log(len)
                for(let i =0; i<len; i++ ){
                    tmp.push({date:Object.keys(data.dates)[i], treat:Object.values(data.dates)[i]} )
                }
                setServices(tmp.sort((a,b)=>{
                    const aTime = new Date(a.date).getTime()
                    const bTime = new Date(b.date).getTime()

                    const aDelta = Math.abs(time - aTime)
                    const bDelta = Math.abs(time - bTime)
                    return (aDelta -bDelta)

                }))
                console.log("sort",services);
              } else {
                console.log("No documents found with given slug")
              }
        })
       
        
    }
    
    return(
        <div className='call_fault'>
            <div className='callandfault'>
            <h1>טבלה קריאות ותקלות- {name}</h1>
            <table className='fault_table'>
                <thead>
                    <tr>
                        <th>תאריך</th>
                        <th>קריאה</th>
                        <th>תקלה</th>
                    </tr>
                </thead>
                <tbody>
                    {all.map((val)=>{
                        return(
                            
                            <tr key={val.id}>
                                <td>{val.date}</td>
                                <td>{val.call}</td>
                                <td>{val.fault}</td>
                            </tr>
                        )
                    })}
                   

                </tbody>
                

            </table>
            </div>
            <div className='treats'>
                <h1>טיפולים</h1>
                <table className='treat_table'>
                    <thead>
                        <tr>
                            <th>תאריך</th>
                            <th>הסבר הטיפול</th>
                        </tr>
                    </thead>
                    <tbody>
                        {services.map((val,i)=>{
                            return(
                                <tr key={i} >
                                    <td>{val.date}</td>
                                    <td>{val.treat}</td>
                                
                                </tr>
                            )
                        })}
                     
                   

                    </tbody>

                </table>
            </div>

             
        </div>

    )
}
export default CallsFault