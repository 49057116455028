import React, { useState } from 'react'
import './Login.css';
import {useNavigate} from 'react-router-dom';

function Login() {
    const [values, setValues] =useState({
        name: '',
        password: ''} 
    )
    const navigate = useNavigate()
    
      
    const handleSubmit=(e)=>{
        e.preventDefault()
        // fetch('http://localhost:9000/register',{
        //     method: 'POST',
        //     headers: {"Access-Control-Allow-Origin": "*",
        //     'Content-Type': 'application/json'},
        //     body: JSON.stringify(values),

        // }).then((response)=> response.json()).then((data)=>{
        //     if(data.message === "error" )  alert("not save", data.mess)
        // })
       
        fetch('https://server-maof.onrender.com/projects/login',{
            method: 'POST',
            headers: {'Content-Type': 'application/json',
            "Accept": "application/json"},
            body: JSON.stringify({
                name: values.name,
                password: values.password
            }),
            
        }).then((response)=> 
        //    if(response.status === 500) return alert("הסיסמא שגויה נסה שוב")
        //    return
         response.json()
        
        ).then((result)=>{
            console.log( result.data.projectNum)
            if(result.status === 'error' ){
                
               return alert("הסיסמא שגויה נסה שוב")

             }
            
             else if(result.status === 'success' && result.data.projectNum == process.env.REACT_APP_PASSWORD){
                navigate('/manager',{state: process.env.REACT_APP_MANAGER_NAME})
            }
            else if (result.status === 'success'){
                console.log(result.data.projectNum)
                navigate('/כניסת משתמש',{state: result.data.projectNum})
            }
        }).catch((err)=>{console.log(err)})
    }

       
    return (
        <div className='login'>
            
            <h2 className='title-login'> השם משתמש והסיסמא שקיבלתם מהחברה </h2>
            <h3 className='txt-login'>בחברת מעוף מעליות ניתן לעקוב אחר תהליך הוספת מעלית</h3>
            <form className='inputs' onSubmit={handleSubmit}>
                <i className="fas fa-user icon-user"></i>
                <input required className='input-login' name="username" placeholder="שם משתמש" type="name" onChange={e=>setValues({...values, name: e.target.value})} />
                <br/>
                <i className="fas fa-lock"></i>
                <input required className='input-login' name="password" placeholder="סיסמא" type="password" onChange={e=>setValues({...values, password: e.target.value})}/>
                <br/>
                <button className='btn-login' type='submit' >התחבר </button>
                
            </form>
            
            
           
            
        </div>

    );
}
export default Login