import React,{useState, useEffect} from 'react'
//import '../../App.css'
import './ServiceTracking.css'
import {collection,  doc, setDoc, getDocs,deleteDoc} from 'firebase/firestore'
import {db} from '../../firebase-config'
import Popup from '../Popup'
import {Bar} from 'react-chartjs-2';
import {useNavigate} from 'react-router-dom'
import { Chart, registerables } from 'chart.js';
import Logout from '../Logout'
import UserCard from '../UserCard'
import NewProjectFirebase from '../NewProjectFirebase'

Chart.register(...registerables); 


function ServiceTracking(){

    ///
    const [popupCreateNew, setCreate] = useState(false)
    ///
    const navigate = useNavigate()
  
    const [placesList, setPlacesList ] = useState([])
    
    useEffect(()=>{
        const getPlacesList = async ()=>{
            const data = await getDocs(collection(db,"serviceTracking"))
            setPlacesList(data.docs.map((doc)=>({...doc.data(),id: doc.id.toString(), visitTime: doc.data().visitTime,nextVisit: doc.data().nextVisit , date: FindLastDate(Object.keys(doc.data().dates)) ,count: totalVisitYear(Object.keys(doc.data().dates))})))
            // Object.keys(doc.data())
            
        }
        
        getPlacesList()


    },[placesList])
    
    const totalVisitYear = (arr)=>{
        const yearNow = new Date()
        const y = yearNow.getFullYear()
        let c = 0
        for(let i= 0; i< arr.length; i++){
            let year = arr[i].split('-')[0]
            if(year == y){
               
                c = c+1
            }
            
        }
        
        return c
  
    }
    const FindLastDate=(arr)=>{
        //let [y,m,d] = arr[0].split('-')
        let tmp = arr[0]
        //console.log(arr)
        for(let i = 1 ; i<arr.length; i++){
            let [y,m,d] = tmp.split('-')
            let [year,month,day] = arr[i].split('-')
            if(y<year ){
                tmp  = ''
                tmp = arr[i]
                
            }
            if(y === year && m < month){
                tmp  = ''
                tmp = arr[i]
            }
            if(y === year && m === month && d <day){
                
                tmp = ''
                tmp = arr[i]
            }

        }
        return tmp

       
    } 
    const state = {
        labels: placesList.map((doc)=>doc.id),
        datasets: [
          {
            label: 'מספר ביקורים',
            backgroundColor: 'rgba(75,192,192,1)',
            borderColor: 'rgba(0,0,0,1)',
            borderWidth: 2,
            data: placesList.map((doc)=>doc.count)
          }
        ]
      }
    
    // const toTable=(id)=>{
    //     navigate(`/קריאה ותקלה/${id}`)

    // }
    const OCpopupCreate=()=>{
        setCreate(!popupCreateNew)
    }
    return(
        <div className='service-tracking'>
            <Logout/>
            <h1 className='title text-center'>מעקב אחרי שירות</h1>
            <div className='grid-btn'>
                <button className='btn btn-secondary m-0' onClick={()=>{navigate('/טיפולים')}}>לצפייה בכל הטיפולים</button>
                <button className='btn btn-secondary m-0 ' onClick={()=>{navigate('/שירות')}}>לצפייה בכל הקריאות והתקלות</button>
            </div>
            
            <div className='add_new_project m-5'>
                <h6>הוסף פרויקט חדש שלא מופיע ברשימה</h6>
                <button className='btn btn-success' onClick={OCpopupCreate}>צור פרויקט חדש</button>
                <div>
           {popupCreateNew && <Popup handleClose={OCpopupCreate}
           content={<NewProjectFirebase  handleClose={OCpopupCreate}/> 
           } />}
         </div>
            </div>
            <div className='list-place'>
                
             {placesList.map((val, index)=>{
                 return(
                     <div className='values' key={index}>
                        <UserCard id={val.id} date={val.date} count={val.count} visitTime={val.visitTime} nextVisit={val.nextVisit} />
                        
                    </div>
                 )
             })}
            </div>
            
            <div className='graphh'>
                <h3 className='title_graph'>כמות ביקורים במהלך השנה הנוכחית</h3>
            <Bar 
          data={state}
          options={{
            
            legend:{
              display:true,
              position:'top'
            }
          }}
        />
               
            </div>
            
        </div>
    );
}
export default ServiceTracking