import React, { useState } from "react";
import {doc, setDoc} from 'firebase/firestore'
import {db} from '../firebase-config'

function NewProjectFirebase(props){

    const [values, setValues] = useState({
        username: '',
        visitTime: '',
        elevatorRecepDate: ''
    })
    const createNew =async(e)=>{
        e.preventDefault()
        console.log(values.username)
        const nextVisit = new Date(values.elevatorRecepDate)
        nextVisit.setDate(nextVisit.getDate() + parseInt(values.visitTime))
        console.log(nextVisit.toLocaleString().split(',')[0])

        const serCollectionRef = doc(db, "serviceTracking", values.username)
        await setDoc(serCollectionRef ,{"visitTime": values.visitTime,"nextVisit": nextVisit.toISOString().split('T')[0], "elevatorRecepDate": values.elevatorRecepDate, "dates": {[values.elevatorRecepDate]: "תאריך קבלת מעלית"}},{merge: true}).then(()=>{
            console.log("create")
            setValues({ 
                username: '',
            visitTime: '',
            elevatorRecepDate: ''})
            props.handleClose()
        }).catch((err)=>console.log(err))
    }
    return(
        <div>
            <form onSubmit={createNew} className="d-flex align-items-center flex-column">
                <div className="m-2">
                    <label><strong>שם פרויקט</strong></label>
                    <input type="name" placeholder="רחוב+מספר בית+עיר" name="username" value={values.username} onChange={e => setValues({...values, username: e.target.value})}/>
                </div>
                <div className="m-2">
                    <label><strong>כל כמה זמן נבוא לביקור?</strong></label>
                    <input type="tel" placeholder="30 או 60" name="visitTime" value={values.visitTime} onChange={e=>setValues({...values, visitTime: e.target.value})}/>

                </div>
                <div className="m-2">
                    <label><strong>תאריך קבלת המעלית</strong></label>
                    <input type="date" name="elevatorRecepDate" placeholder="" value={values.elevatorRecepDate} onChange={e=>setValues({...values, elevatorRecepDate: e.target.value})}/>
                </div>
                <button type="submit" className="btn btn-success d-flex justify-content-center ">צור חדש</button>
            </form>
        </div>
    )
}
export default NewProjectFirebase;