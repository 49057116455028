import React  from 'react'
import '../../App.css'
import './AboutUs.css'
import {about} from "../../data"
import 'bootstrap-icons/font/bootstrap-icons.css'

function AboutUs(){

  return (
    <div className="aboutUs">
      <h1 className="title-service">אודות - מעוף מעליות</h1>

      <div className="container-about">
        {about.map((item, index)=>{
          return(
            <div className="Paragraph about2" key={index}>
              
              <div className="icon-about i2">
                <i className={item.icon}></i>
              </div>
              <h4 className="title-p-about">{item.title}</h4>
              <p className="ppp">{item.descrip}</p>

            </div>
          )
        })}
       
      </div>
    </div>
  );
}
export default AboutUs;