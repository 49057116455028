
import React, { useEffect, useState } from "react";
import { useNavigate} from "react-router-dom";

import './Logout.css'
function Logout(){
    const [auth, setAuth] = useState(false)
    // const [Message, setMessage] =useState('')
    // const [name, setName]= useState('')
  
    const navigate = useNavigate()

    useEffect(()=>{
        // axios.get('https://elevators-maof.herokuapp.com').then(res => {
        //     if(res.data.Status === "Success"){
        //         console.log(res.data.name)
        //         setAuth(true)
        //         setName(res.data.name)
        //     } else{
        //         setAuth(false)
        //         setMessage(res.data.Error)
        //     }
        // }).then(err=> console.log(err))
        ///////////////////////
    //     fetch('http://localhost:9000',{
    //         method: 'GET',
    //     headers: {'Content-Type': 'application/json',
    //           "Accept": "application/json"}
  
    //   }).then((response) => response.json()).then((data)=>{
    //     if(data.Status === "Success"){
    //         // console.log(data.name)
    //         setAuth(true)
    //         // setName(res.data.name)
    //     } else{
    //         setAuth(false)
    //         // setMessage(data.Error)
    //     }
    //     }).then(err=> console.log(err))

    },[])
    const handleLogout =()=>{
        fetch('http://localhost:9000/projects/logout',{
            method: 'GET',
            headers: {'Content-Type': 'application/json',
                  "Accept": "application/json"}
        }).then(response=>response.json()).then(res=>{
            // window.location.reload(true)
            if(res.Status === "Seccess"){
                navigate('/התחברות')
            }
            
        }).catch(err=>console.log(err))
    }
    return(
        <div className="logout-btn">
            <button className="btn btn-primary m-0 " onClick={handleLogout}>התנתק</button>

        </div>
    )
}
export default Logout;